/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





jQuery(document).ready(function($) {
	
	
	// Cache the Window object
	var $window = $(window);
	
	
	
	
	// MAIN MENU DROP DOWNS
	$("ul#menu-primary-navigation li.menu-item-has-children").mouseenter(function() {
		$(this).children(".sub-menu").stop();
		$(this).children(".sub-menu").slideDown("fast");
	});
	$("ul#menu-primary-navigation li.menu-item-has-children").mouseleave(function() {
		$(this).children(".sub-menu").stop();
		$(this).children(".sub-menu").slideUp("fast");
	});
	
	
	// EXPANDERS
	$(".trigger").click(function() {
		
		$('html, body').animate({ scrollTop: $('#team').offset().top  - 100}, 300);
		
		$(".expander").removeClass("active");
		$(".expander-content").slideUp('fast');
		
		$(this).parent(".expander").addClass("active");
		$(this).siblings(".expander-content").slideDown('fast');
	});
	
	$( ".jobs-list h3").click(function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('active');
	})
	
	
	// MOBILE NAV
	$(".mobile-menu-button").click(function() {
		$("#menu-primary-navigation").slideToggle('fast');
	});
	
	$("li.menu-item-has-children > a").click(function(e) {
		
		var w = $(window).width();
		if (w < 390) {
			e.preventDefault();
			$(this).children('.sub-menu').slideToggle('fast');
		}
	});
	
	$(".screen-reader-text").click(function() {
		var w = $(window).width();
		if (w < 390) {
			$(".search-field").slideToggle('fast');
		}
	});
	
	
	
	
	// COLORBOX LINKS
	
/*

	$(".colorbox.youtube").colorbox({iframe:true, innerWidth:"80%", innerHeight:"80%"});
	$(".colorbox.vimeo").colorbox({iframe:true, innerWidth:"80%", innerHeight:"80%"});
	$(".colorbox.iframe").colorbox({iframe:true, width:"80%", height:"80%"});
	$(".colorbox").colorbox();
*/
	
	
	// PARALLAX HEADERS
	          
	$('div[data-type="background"]').each(function(){
		var $bgobj = $(this); // assigning the object
		
		
		$(window).scroll(function() {
			
			if ( $(window).width() > 800 ) {
				
			        
				// Scroll the background at var speed
				// the yPos is a negative value because we're scrolling it UP!								
				var yPos = -($window.scrollTop() / $bgobj.data('speed')); 
				
				// Put together our final background position
				var coords = '50% '+ yPos + 'px';
				
				// Move the background
				$bgobj.css({ backgroundPosition: coords });
				
				//console.log(yPos);
			}
			
		}); // window scroll Ends
		
	}); 

	
	
});

